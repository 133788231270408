import { getCommonParamsItemLabel } from '@/utils/common.js'

export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'sn',
      label: 'terminal.sn',
      width: '150'
    },
    {
      prop: 'operationType',
      label: 'log.operation-type'
    },
    {
      prop: 'message',
      label: 'log.message'
    },
    {
      prop: 'ipAddress',
      label: 'log.ip-address'
    },
    {
      prop: 'location',
      label: 'log.location'
    },
    {
      prop: 'responseCode',
      label: 'terminal.response-result',
      formatter: (row) => {
        return getCommonParamsItemLabel(String(row), 'terminal_response_code')
      }
    },
    {
      prop: 'createdBy',
      label: 'general.operator'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'Operation Time'
    }
    // {
    //   prop: 'operations',
    //   label: 'common.app-operation',
    //   width: '300',
    //   slotName: 'handler'
    // }
  ],
  showIndexColumn: true
  // showSelectColumn: true
}
